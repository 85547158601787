import React from 'react'
import News from '../../main/breakingnews/News'
import "../../main/breakingnews/News.css"

const BlogSection = () => {
    return (
        <News/>
    )
}

export default BlogSection
